.main {
	position: relative;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
}
.head {
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	height: 700px;
	&-box {
		position: relative;
		text-align: center;
		&__title {
			position: relative;
			color: $colorWhite;
			font-size: 22px;
			font-weight: 700;
			letter-spacing: 5px;
			text-transform: uppercase;
			margin-bottom: 26px;
			h1 {
				font-weight: 700;
			}
		}
		&__subtitle {
			position: relative;
			color: $colorWhite;
			font-size: 54px;
			font-weight: 800;
			line-height: 1.185;
			&:not(:last-child) {
				margin-bottom: 45px;
			}
		}
		&__button {
			position: relative;
			.btn {
				min-width: 295px;
			}
		}
	}
}
.about {
	position: relative;
	&-inner {
		position: relative;
		padding-top: 30px;
	}
	&-heading {
		position: absolute;
		top: 0;
		left: 50%;
		color: $colorYellow;
		font-size: 100px;
		font-weight: 700;
		letter-spacing: 1px;
		margin-left: 30px;
		-webkit-text-fill-color: transparent;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: $colorYellow;
		transform: translateX(-50%);
		white-space: nowrap;
		pointer-events: none;
		z-index: 1;
	}
	&-text {
		position: relative;
		font-size: 17px;
		line-height: 1.53;
		padding-top: 30px;
		padding-left: 40px;
		p {
			&:not(:last-child) {
				margin-bottom: 30px;
			}
		}
	}
	&-img {
		position: relative;
		padding-top: 20px;
	}
}
.benefits {
	position: relative;
	background: $colorBlue;
	padding: 80px 0;
	.title {
		&:before {
			content: none;
		}
		h2 {
			color: $colorWhite;
		}
	}
	&-list {
		position: relative;
		padding-top: 20px;
	}
	&-box {
		position: relative;
		background: $colorWhite;
		top: 0;
		min-height: 100%;
		padding: 40px;
		transition: all 0.3s ease;
		&:before {
			content: '';
			position: absolute;
			background: url('../img/waves.svg');
			background-repeat: no-repeat;
			top: 60px;
			right: 40px;
			width: 80px;
			height: 12px;
		}
		&:after {
			content: '';
			position: absolute;
			background: $colorYellow;
			left: 0;
			bottom: 0;
			width: 0;
			height: 5px;
			transition: all 0.3s ease;
		}
		&:hover {
			top: -10px;
			&:after {
				width: 100%;
			}
		}
		&__icon {
			position: relative;
			margin-bottom: 30px;
			img {
				max-height: 48px;
			}
		}
		&__title {
			position: relative;
			color: $colorBlack;
			font-size: 22px;
			font-weight: 700;
			line-height: 1;
			margin-bottom: 20px;
		}
		&__text {
			position: relative;
			font-size: 17px;
			line-height: 1.295;
		}
	}
}
.history {
	position: relative;
	&-heading {
		position: absolute;
		right: 0;
		bottom: 0;
		color: $colorYellow;
		font-size: 100px;
		font-weight: 700;
		letter-spacing: 1px;
		border-top: 3px solid $colorYellow;
		border-bottom: 3px solid $colorYellow;
		-webkit-text-fill-color: transparent;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: $colorYellow;
		padding: 15px 0;
		transform: rotate(90deg) translateX(-70px);
		white-space: nowrap;
		pointer-events: none;
		z-index: 1;
	}
	&-inner {
		position: relative;
	}
	&-text {
		position: relative;
		font-size: 17px;
		line-height: 1.53;
		padding-top: 30px;
		padding-left: 40px;
		p {
			&:not(:last-child) {
				margin-bottom: 30px;
			}
		}
	}
}
.products {
	position: relative;
	background: $colorBg;
	&-inner {
		position: relative;
		padding-top: 30px;
	}
		&-tabs {
			position: relative;
			margin-bottom: 40px;
			ul {
				display: flex;
				align-items: flex-start;
				li {
					position: relative;
					color: $colorDarkText;
					font-size: 17px;
					line-height: 1.4;
					transition: color 0.3s ease;
					cursor: pointer;
					&:not(:last-child) {
						margin-right: 92px;
					}
					&:hover {
						color: $colorBlue;
					}
					&.active {
						color: $colorBlue;
						&:before {
							width: 100%;
						}
					}
					&:before {
						content: '';
						position: absolute;
						background: $colorBlue;
						left: 0;
						right: 0;
						bottom: -10px;
						height: 2px;
						width: 0;
						transition: all 0.3s ease;
					}
				}
			}
		}
		&-content {
			position: relative;
		}
			&-box {
				position: relative;
				display: none;
				margin-bottom: -30px;
				&.show {
					display: block;
				}
				[class*="col-"] {
					margin-bottom: 30px;
				}
			}
			&-item {
				position: relative;
				display: block;
				background: rgba($colorBlack, 0.1);
				background: $colorWhite;
				line-height: 0;
				padding-top: 56.25%;
				&:before {
					content: '\EA03';
					position: absolute;
					display: flex;
					background: $colorYellow;
					align-items: center;
					justify-content: center;
					top: 50%;
					left: 50%;
					width: 60px;
					height: 60px;
					color: $colorWhite;
					font-size: 28px;
					font-family: 'svgfont', sans-serif;
					border-radius: 50%;
					transform: translate(-50%, -50%);
					pointer-events: none;
					transition: all 0.3s ease;
					visibility: hidden;
					opacity: 0;
					z-index: 1;
				}
				&.video {
					&:before {
						content: '\EA02';
						font-size: 22px;
						text-indent: 5px;
					}
				}
				&:hover {
					&:before {
						visibility: visible;
						opacity: 1;
					}
				}
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					max-height: 100%;
					transform: translate(-50%, -50%);
				}
				iframe,
				video {
					position: absolute;
					display: none;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					max-width: 100%;
					max-height: 100%;
				}
			}
}
.contacts {
	position: relative;
	min-height: 480px;
	padding: 60px 0;
	.title {
		display: inline-block;
		&:before {
			top: -20px;
			right: 18px;
			left: auto;
			bottom: auto;
		}
	}
	&-heading {
		position: absolute;
		top: 50%;
		left: 100%;
		color: $colorYellow;
		font-size: 100px;
		font-weight: 700;
		letter-spacing: 1px;
		-webkit-text-fill-color: transparent;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: $colorYellow;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}
	&-box {
		position: relative;
		background: $colorWhite;
		width: 570px;
		padding: 60px;
		z-index: 1;
		&__group {
			position: relative;
			&:not(:last-child) {
				margin-bottom: 40px;
			}
			&-title {
				position: relative;
				font-size: 17px;
				line-height: 1.53;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}
			&-text {
				position: relative;
				color: $colorBlack;
				font-size: 19px;
				font-weight: 600;
				line-height: 1.368;
				a {
					color: $colorBlack;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.map {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
