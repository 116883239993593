.footer {
	position: relative;
	background: $colorBlack;
	padding: 20px 0;
	&-inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.copyright {
	position: relative;
	color: $colorLightText;
	font-size: 13px;
	font-weight: 500;
}
.develop {
	position: relative;
	&-modal {
		position: absolute;
		background: $colorWhite;
		right: 0;
		bottom: calc(100% + 10px);
		width: 180px;
		color: $colorText;
		font-size: 12px;
		line-height: 1.25;
		border: 1px solid $colorBg;
		padding: 5px 10px;
		transition: all 0.3s ease;
		visibility: hidden;
		opacity: 0;
	}
	a {
		display: block;
		line-height: 0;
		&:hover {
			.develop-modal {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	img {
		width: 28px;
	}
}
