@font-face
	font-family: "svgfont"
	src: url('fonts/svgfont.eot')
	src: url('fonts/svgfont.eot?#iefix') format('eot'), url('fonts/svgfont.woff') format('woff'), url('fonts/svgfont.woff2') format('woff2'), url('fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-close: "\EA01"
$icon-i-play: "\EA02"
$icon-i-glass: "\EA03"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-close:before
	content: $icon-close
.icon-i-play:before
	content: $icon-i-play
.icon-i-glass:before
	content: $icon-i-glass