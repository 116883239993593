.btn {
	position: relative;
	display: inline-block;
	background: $colorYellow;
	height: 56px;
	color: $colorWhite;
	font-size: 18px;
	font-weight: 700;
	font-family: 'Mazzard', sans-serif;
	line-height: 56px;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
	border: 0;
	border-radius: 28px;
	box-shadow: none;
	padding: 0 15px;
	transition: all 0.3s ease;
	outline: none;
	cursor: pointer;
	&:hover,
	&:active {
		background: $colorWhite;
		color: $colorYellow;
		text-decoration: none;
		outline: none;
	}
	&:focus {
		box-shadow: none;
		outline: none;
	}
	&.disabled,
	&[disabled] {
		pointer-events: none;
		opacity: 0.5;
		cursor: auto;
	}
}
