.header {
	position: fixed;
	background: $colorWhite;
	top: 0;
	left: 0;
	right: 0;
	height: 80px;
	padding: 20px 0;
	z-index: 50;
	&-inner {
		position: relative;
		display: flex;
		align-items: center;
	}
}
.logo {
	position: relative;
	img {
		max-height: 40px;
	}
}
.top-menu {
	position: relative;
	margin-left: auto;
	ul {
		display: flex;
		align-items: center;
		li {
			position: relative;
			&:not(:first-child) {
				margin-left: 106px;
			}
			a {
				position: relative;
				display: block;
				color: $colorDarkText;
				font-size: 17px;
				line-height: 1.53;
				transition: color 0.3s ease;
				&:hover {
					color: $colorBlue;
				}
				&.active {
					color: $colorBlue;
				}
			}
		}
	}
}
.menu-toggle {
	position: relative;
	display: none;
	width: 30px;
	height: 24px;
	cursor: pointer;
	&.active {
		span {
			background: transparent;
			&:before {
				transform: rotate(45deg) translate(5px, 5px);
			}
			&:after {
				transform: rotate(-45deg) translate(5px, -5px);
			}
		}
	}
	span {
		position: absolute;
		background: $colorBlack;
		top: 50%;
		left: 50%;
		width: 24px;
		height: 2px;
		margin-left: -12px;
		transition: all 0.3s ease;
		&:before,
		&:after {
			content: '';
			position: absolute;
			background: $colorBlack;
			left: 0;
			width: 24px;
			height: 2px;
			transition: all 0.3s ease;
		}
		&:before {
			top: -7px;
		}
		&:after {
			top: 7px;
		}
	}
}
