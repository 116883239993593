$colorBlack: #000;
$colorWhite: #FFF;
$colorBg: #F5F6F8;
$colorText: #4D5666;
$colorLightText: #AAAAAA;
$colorDarkText: #020202;
$colorYellow: #FDB604;
$colorBlue: #0387D4;
$colorGreen: #00CC00;
$colorLightGreen: lighten($colorGreen, 10);
$colorDarkGreen: darken($colorGreen, 10);
$colorRed: #CC0000;
$colorLightRed: lighten($colorRed, 10);
$colorDarkRed: darken($colorRed, 10);

html,
body {
	height: 100%;
}
body {
	color: $colorText;
	font-size: 14px;
	font-family: 'Mazzard', sans-serif;
	line-height: 1.25;
}
* {
	outline: none;
	box-sizing: border-box;
	&:before,
	&:after {
		box-sizing: border-box;
	}
}
h1, h2, h3, h4, h5, h6 {
	font-size: 1em;
	line-height: 1;
	margin: 0;
}
img {
	vertical-align: top;
	max-width: 100%;
}
a {
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}
ul,
ol {
	list-style-type: none;
	list-style-position: inside;
	padding: 0;
	margin: 0;
}
p {
	margin: 0;
}
button {
	outline: none;
	&:focus{
		outline: none;
	}
}
[class^='icon-'],
[class*=' icon-'] {
	position: relative;
	vertical-align: middle;
}
.container {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;
}
.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 320px;
	min-height: 100%;
	padding-top: 80px;
	overflow: hidden;
}
.section {
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 120px 0;
}
.title {
	position: relative;
	&:not(:last-child) {
		margin-bottom: 35px;
	}
	&:before {
		content: '';
		position: absolute;
		background: url('../img/waves.svg');
		background-repeat: no-repeat;
		left: 0;
		bottom: -35px;
		width: 80px;
		height: 12px;
	}
	h2 {
		color: $colorBlack;
		font-size: 36px;
		font-weight: 700;
		line-height: 1.195;
	}
}
.text {
	&-center {
		text-align: center !important;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.col,
[class*="col-"]{
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	&-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	@for $i from 1 through 12 {
		&-#{$i} {
			flex: 0 0 calc(#{$i} * 100% / 12);
			max-width: calc(#{$i} * 100% / 12);
		}
	}
}
.justify {
	&-content {
		&-between {
			justify-content: space-between!important;
		}
	}
}
