@media screen and (min-width: 576px) {
	.container {
		max-width: 540px;
	}
	.col {
		&-sm {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
			&-auto {
				flex: 0 0 auto;
				width: auto;
				max-width: 100%;
			}
			@for $i from 1 through 12 {
				&-#{$i} {
					flex: 0 0 calc(#{$i} * 100% / 12);
					max-width: calc(#{$i} * 100% / 12);
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.container {
		max-width: 720px;
	}
	.col {
		&-md {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
			&-auto {
				flex: 0 0 auto;
				width: auto;
				max-width: 100%;
			}
			@for $i from 1 through 12 {
				&-#{$i} {
					flex: 0 0 calc(#{$i} * 100% / 12);
					max-width: calc(#{$i} * 100% / 12);
				}
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.container {
		max-width: 960px;
	}
	.col {
		&-lg {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
			&-auto {
				flex: 0 0 auto;
				width: auto;
				max-width: 100%;
			}
			@for $i from 1 through 12 {
				&-#{$i} {
					flex: 0 0 calc(#{$i} * 100% / 12);
					max-width: calc(#{$i} * 100% / 12);
				}
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.container {
		max-width: 1140px;
		padding: 0;
	}
	.col {
		&-xl {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
			&-auto {
				flex: 0 0 auto;
				width: auto;
				max-width: 100%;
			}
			@for $i from 1 through 12 {
				&-#{$i} {
					flex: 0 0 calc(#{$i} * 100% / 12);
					max-width: calc(#{$i} * 100% / 12);
				}
			}
		}
	}
}

@media screen and (max-width: 1199px) {
	.top-menu {
		ul {
			li {
				&:not(:first-child) {
					margin-left: 32px;
				}
			}
		}
	}
	.section {
		padding: 100px 0;
	}
	.about {
		&-text {
			padding-left: 0;
		}
	}
	.benefits {
		padding: 80px 0;
	}
	.products {
		&-tabs {
			ul {
				li {
					&:not(:last-child) {
						margin-right: 30px;
					}
				}
			}
		}
	}
	.contacts {
		padding: 60px 0;
	}
}

@media screen and (max-width: 991px) {
	.top-menu {
		position: fixed;
		background: $colorWhite;
		top: 80px;
		right: -100vw;
		bottom: 0;
		width: 100vw;
		transition: all 0.3s ease;
		&.show {
			right: 0;
		}
		ul {
			position: absolute;
			display: block;
			top: 50%;
			left: 50%;
			text-align: center;
			transform: translate(-50%, -50%);
			li {
				&:not(:first-child) {
					margin-left: 0;
				}
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				a {
					font-size: 24px;
				}
			}
		}
	}
	.menu-toggle {
		display: block;
		margin-left: auto;
	}
	.section {
		padding: 70px 0;
	}
	.head {
		&-box {
			&__title {
				font-size: 20px;
			}
			&__subtitle {
				font-size: 42px;
			}
		}
	}
	.about {
		&-heading {
			display: none;
		}
		&-inner {
			padding-top: 0;
		}
		&-text {
			margin-bottom: 30px;
			p {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
	}
	.benefits {
		&-list {
			[class*="col-"] {
				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
		}
	}
	.history {
		&-heading {
			display: none;
		}
		&-text {
			padding-left: 0;
			&-text {
				p {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}
			}
		}
	}
	.contacts {
		padding: 50px 0;
		&-heading {
			display: none;
		}
		&-box {
			width: 420px;
			padding: 50px 30px;
		}
	}
}

@media screen and (max-width: 767px) {
	.wrapper {
		padding-top: 50px;
	}
	.btn {
		height: 50px;
		line-height: 50px;
	}
	.title {
		&:not(:last-child) {
			margin-bottom: 30px;
		}
		&:before {
			bottom: -30px;
		}
		h2 {
			font-size: 27px;
		}
	}
	.header {
		height: 50px;
		padding: 10px 0;
	}
	.logo {
		img {
			max-height: 30px;
		}
	}
	.top-menu {
		top: 50px;
		ul {
			width: 290px;
			li {
				a {
					font-size: 17px;
				}
			}
		}
	}
	.section {
		padding: 40px 0;
	}
	.head {
		height: calc(100vh - 50px);
		&-box {
			&__title {
				font-size: 18px;
				margin-bottom: 20px;
			}
			&__subtitle {
				font-size: 34px;
				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
			&__button {
				.btn {
					min-width: 240px;
					font-size: 17px;
				}
			}
		}
	}
	.about {
		&-text {
			font-size: 15px;
			p {
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}
		&-img {
			padding-top: 0;
		}
	}
	.benefits {
		&-list {
			padding-top: 0;
		}
			&-box {
				padding: 30px;
				&:before {
					top: 50px;
				}
				&__title {
					font-size: 20px;
					margin-bottom: 15px;
				}
				&__text {
					font-size: 15px;
				}
			}
	}
	.history {
		&-text {
			font-size: 15px;
			p {
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}
	}
	.products {
		&-tabs {
			margin-bottom: 30px;
			ul {
				display: block;
				li {
					font-size: 15px;
					&:not(:last-child) {
						margin-right: 0;
						margin-bottom: 20px;
					}
					&:before {
						bottom: -5px;
					}
				}
			}
		}
		&-content {
			margin-bottom: -15px;
		}
		&-box {
			[class*="col-"] {
				margin-bottom: 15px;
			}
		}
	}
	.contacts {
		min-height: 0;
		padding: 70px 0 0;
		&-box {
			position: relative;
			width: auto;
			padding: 0;
			margin-bottom: 30px;
			&__group {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
				&-title {
					font-size: 15px;
					&:not(:last-child) {
						margin-bottom: 5px;
					}
				}
				&-text {
					font-size: 16px;
				}
			}
		}
	}
	.map {
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		height: 400px;
	}
	.footer {
		padding: 10px 0;
	}
	.copyright {
		font-size: 11px;
	}
}

@media screen and (max-width: 575px) {

}
